<!--
 * @Author: your name
 * @Date: 2020-10-26 15:19:09
 * @LastEditTime: 2020-10-29 14:10:58
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \trunk\src\pages\property\propertyView.vue
-->
<template>
  <div class="propertyView commonF" style="padding: 16px 0; background-color: #fff;">
    <infoTable :tableData="tableData" :num='1'/>
    <el-button type="primary" @click="$router.go('-1')" style="display: block; margin: 20px auto">返回</el-button>
  </div>
</template>
<script>
import infoTable from '@/components/infoTable/infoTableProperty';
export default {
  components: {
    infoTable
  },
  data () {
    return {
      tableData: {
        
      },
      viewData: {

      }
    }
  },
  methods: {
    setTableData() {
      this.tableData = [
        {
          value: this.viewData.operationName,
          name: '所属商户：'
        },
        {
          value: this.viewData.assetsCode,
          name: '物品编号：'
        }, {
          value: this.viewData.assetsName,
          name: '物品名称：'
        }, {
          value: this.viewData.assetsTypeParentName,
          name: '物品类型：'
        }, {
          value: this.viewData.assetsTypeName,
          name: '物品型号：'
        }, {
          value: this.viewData.status == 1 ? '认领' : (this.viewData.status == 0 ? '空闲' : (this.viewData.status == 2 ? '废弃' : '')),
          name: '状态：'
        }, {
          value: this.viewData.pdaManagerName,
          name: '领用人：'
        }
      ]
    }
  },
  mounted() {
    this.viewData = this.$route.query
    this.setTableData()
  }
}
</script>
